<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleSidenav.next()" [fxShow]="!!user" fxShow.lt-md="true">
      <mat-icon>menu</mat-icon>
    </button>

    <a routerLink="/" class="home-button" (click)="closeSidenav.next()">
      <img src="./assets/logo_white.svg" class="logo" />
    </a>

    <span class="spacer"></span>

    <div fxShow="true" fxShow.lt-md="false">
      <ng-container *ngTemplateOutlet="links"></ng-container>
    </div>

    <a
      class="link primary-button"
      mat-raised-button
      (click)="login()"
      *ngIf="userCallState !== 'loading' && !user"
      i18n="@@toolbar-1"
    >
      S'identifier
    </a>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #links>
  <a class="link" [class.active-route]="activeRoute === 'catalog'" mat-button [matMenuTriggerFor]="catalogMenu">
    <span i18n="@@toolbar-2">Catalogue</span>
    <mat-icon>expand_more</mat-icon>
  </a>

  <mat-menu #catalogMenu="matMenu">
    <div *ngIf="categoriesCallState === 'loading'" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <div *ngIf="categoriesCallState === 'error'" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <button mat-icon-button color="primary" (click)="$event.stopPropagation(); fetchCategories.next()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="categoriesCallState === 'loaded'">
      <a
        mat-menu-item
        *ngFor="let category of filteredCategories"
        routerLink="/catalogue/categories/{{ category.id }}"
        routerLinkActive="mat-menu-item-highlighted"
        >{{ category.label | localizedString }}</a
      >
    </ng-container>
  </mat-menu>

  <a class="link" mat-button routerLink="/nos-plans" routerLinkActive="active-route" i18n="@@toolbar-3"> Nos plans </a>

  <a class="link" [class.active-route]="activeRoute === 'how-it-works'" mat-button [matMenuTriggerFor]="howItWorksMenu">
    <span i18n="@@toolbar-4">Comment ça marche</span>
    <mat-icon>expand_more</mat-icon>
  </a>

  <mat-menu #howItWorksMenu="matMenu">
    <a
      mat-menu-item
      routerLink="/information/ingenieur"
      routerLinkActive="mat-menu-item-highlighted"
      i18n="@@toolbar-5"
    >
      Recevoir une formation
    </a>
    <a mat-menu-item routerLink="/information/expert" routerLinkActive="mat-menu-item-highlighted" i18n="@@toolbar-6">
      Offrir une formation
    </a>
    <a mat-menu-item routerLink="/information/faq" routerLinkActive="mat-menu-item-highlighted" i18n="@@toolbar-7">
      Questions fréquentes
    </a>
  </mat-menu>

  <a class="link" mat-button routerLink="/a-propos" routerLinkActive="active-route" i18n="@@toolbar-8"> À propos </a>

  <a class="link" mat-button routerLink="/nous-contacter" routerLinkActive="active-route" i18n="@@toolbar-9">
    Nous contacter
  </a>

  <a *ngIf="locale === 'fr'" class="link" mat-button (click)="goto('en')"> English </a>

  <a *ngIf="locale === 'en'" class="link" mat-button (click)="goto('fr')"> Français </a>
</ng-template>
