import { Injectable } from '@angular/core';
import { detect } from 'detect-browser';

@Injectable()
export class PwaService {
  isInstalled: boolean;
  isAndroid: boolean;
  isIOS: boolean;

  constructor() {
    this.isInstalled = this.getDisplayMode() === 'standalone';
    const os = detect().os;
    this.isAndroid = os === 'Android OS';
    this.isIOS = os === 'iOS';
  }

  public getDisplayMode() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window.navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches
      ? 'standalone'
      : 'web';
  }

  public canInstall() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return !!(window as any).deferedBeforeInstallPrompt;
  }

  public install() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).deferedBeforeInstallPrompt.prompt();
  }
}
