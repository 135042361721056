import { ownedCertificatesReducer } from './owned-certificates.store';
import { categoriesReducer, CategoriesState } from './categories.store';
import { completedFormationsReducer, CompletedFormationsState } from './completed-formations.store';
import { formationsReducer, FormationsState } from './formations.store';
import { layoutReducer, LayoutState } from './layout.store';
import { ownedFormationsReducer, OwnedFormationsState } from './owned-formations.store';
import { userReducer, UserState } from './user.store';
import { wishlistReducer, WishlistState } from './wishlist.store';

export type AppState = {
  categories: CategoriesState;
  completedFormations: CompletedFormationsState;
  formations: FormationsState;
  layout: LayoutState;
  ownedFormations: OwnedFormationsState;
  user: UserState;
  wishlist: WishlistState;
};

export const appStore = {
  categories: categoriesReducer,
  completedFormations: completedFormationsReducer,
  formations: formationsReducer,
  layout: layoutReducer,
  ownedFormations: ownedFormationsReducer,
  ownedCertificates: ownedCertificatesReducer,
  user: userReducer,
  wishlist: wishlistReducer,
};
