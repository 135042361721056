import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import fr from 'date-fns/locale/fr-CA';
import en from 'date-fns/locale/en-US';
import { MonitoringService } from '~/core';

@Pipe({
  name: 'displayDateToNow',
})
export class DisplayDateToNowPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, private monitoring: MonitoringService) {}

  transform(value: Date): string {
    return formatDistanceToNow(new Date(value), { locale: this.getLocale(), addSuffix: true });
  }

  getLocale() {
    switch (this.locale) {
      case 'fr':
        return fr;
      case 'en':
        return en;
      default:
        this.monitoring.logException(new Error(`Unexpected locale '${this.locale}' while localizing date`));
        return fr;
    }
  }
}
