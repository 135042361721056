import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { Formation } from '~/models';
import { forkJoin, of, OperatorFunction } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService, MonitoringService } from '~/core';
import { AppState } from './app.store';
import {
  AsyncEntityState,
  createAsyncEntityAdapter,
  ErrorEntityState,
  LoadedEntityState,
  LoadingEntityState,
} from './async-entity-state';
import { AsyncState, createAsyncStateAdapter } from './async-state';
import { selectOwnedCertificatesIds } from './owned-certificates.store';
import { selectCompletedFormationsIds } from './completed-formations.store';
import { selectOwnedFormationsIds } from './owned-formations.store';
import { selectWishlist } from './wishlist.store';

export interface FormationsState extends AsyncEntityState<Formation> {
  search: AsyncState<string[], null>;
}

export const formationsAdapter = createAsyncEntityAdapter<Formation>();
export const searchAdapater = createAsyncStateAdapter<string[], null>();

const initialState = formationsAdapter.getInitialState({
  search: searchAdapater.getInitialState(),
});

export const addFormations = createAction('[Formations Store] Add Formations', props<{ formationIds: string[] }>());
export const addFormationsSuccess = createAction(
  '[Formations Store] Add Formations Success',
  props<{ formations: Formation[] }>()
);
export const addFormationsError = createAction(
  '[Formations Store] Add Formations Error',
  props<{ errors: { message: string; status: number }[]; formationIds: string[] }>()
);

export const searchFormations = createAction(
  '[Formations Store] Search Formations',
  props<{
    criteria: {
      categoryIds?: string[];
      tagIds?: string[];
      minPublishedDate?: Date;
      maxPublishedDate?: Date;
      minDuration?: number;
      maxDuration?: number;
      search?: string;
      language?: string;
    };
  }>()
);
export const searchFormationsSuccess = createAction(
  '[Formations Store] Search Formations Success',
  props<{ formations: Formation[] }>()
);
export const searchFormationsError = createAction(
  '[Formations Store] Search Formations Error',
  props<{ error: { message: string; status: number } }>()
);

export const clearFormations = createAction('[Formations Store] Clear Formations');

export const formationsReducer = createReducer<FormationsState>(
  initialState,

  on(addFormations, (state, { formationIds }) => {
    // reload formations that are already in state but are not 'loaded'
    const notLoadedIds = formationIds.filter((formationId) =>
      (state.ids as string[]).filter((id) => state.entities[id].type !== 'loaded').includes(formationId)
    );
    const updatedState = formationsAdapter.updateMany(
      notLoadedIds.map((id) => ({ id, changes: { ...new LoadingEntityState(id), error: undefined } })),
      state
    );
    // add missing formations with state 'loading'
    const absentIds = formationIds.filter((id) => !(state.ids as string[]).includes(id));
    return formationsAdapter.addMany(
      absentIds.map((id) => new LoadingEntityState(id)),
      updatedState
    );
  }),

  on(addFormationsSuccess, (state, { formations }) =>
    formationsAdapter.upsertMany(
      formations.map((formation) => new LoadedEntityState(formation)),
      state
    )
  ),

  on(addFormationsError, (state, { errors, formationIds }) =>
    formationsAdapter.upsertMany(
      formationIds.map((id, index) => new ErrorEntityState(id, errors[index].message, errors[index].status)),
      state
    )
  ),

  on(searchFormations, (state) => ({ ...state, search: searchAdapater.setLoading(state.search) })),

  on(searchFormationsSuccess, (state, { formations }) => ({
    ...state,
    search: searchAdapater.setLoaded(state.search, { value: formations.map((formation) => formation.id) }),
  })),

  on(searchFormationsError, (state, { error }) => ({ ...state, search: searchAdapater.setError(state.search, error) })),

  on(clearFormations, () => initialState)
);

@Injectable()
export class FormationsEffects {
  addFormations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFormations),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectAllFormationsEntities)))),
      switchMap(([{ formationIds }, entities]) => {
        if (!formationIds.length) {
          return of(addFormationsSuccess({ formations: [] }));
        }

        const notLoadedIds = formationIds.filter((id) => entities[id]?.type !== 'loaded');

        return this.fetchFormations(notLoadedIds).pipe(
          this.mapSuccessesAndErrors(),
          switchMap(([successes, errors]) =>
            [
              successes.length ? addFormationsSuccess({ formations: successes.map((success) => success.state) }) : null,
              errors.length
                ? addFormationsError({
                    errors: errors.map((error) => error.error),
                    formationIds: errors.map((error) => error.state.id),
                  })
                : null,
            ].filter(Boolean)
          )
        );
      })
    )
  );

  searchFormations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchFormations),
      switchMap(({ criteria }) =>
        this.api.searchFormations(criteria).pipe(
          map((formations) => searchFormationsSuccess({ formations })),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(searchFormationsError({ error: { message: error.message, status: error.status } }));
          })
        )
      )
    )
  );

  searchFormationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchFormationsSuccess),
      map(({ formations }) => addFormationsSuccess({ formations }))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
    private monitoring: MonitoringService
  ) {}

  private fetchFormations(ids: string[]) {
    return forkJoin(
      ids.map((id) =>
        this.api.getFormation(id).pipe(
          map((formation) => new LoadedEntityState(formation)),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(new ErrorEntityState(id, error.message, error.status));
          })
        )
      )
    );
  }

  private mapSuccessesAndErrors(): OperatorFunction<
    Array<LoadedEntityState<Formation> | ErrorEntityState<Formation['id']>>,
    readonly [LoadedEntityState<Formation>[], ErrorEntityState<Formation['id']>[]]
  > {
    return (results$) =>
      results$.pipe(
        map((results) => {
          const successes = results.filter(
            (result): result is LoadedEntityState<Formation> => result.type === 'loaded'
          );
          const errors = results.filter(
            (result): result is ErrorEntityState<Formation['id']> => result.type === 'error'
          );
          return [successes, errors] as const;
        })
      );
  }
}

const selectors = formationsAdapter.getSelectors();

export const selectFormationsState = createFeatureSelector<FormationsState>('formations');

export const selectAllFormationsEntities = createSelector(selectFormationsState, selectors.selectEntities);

export const selectFormationsIds = createSelector(selectFormationsState, selectors.selectIds);

export const selectFormationsSearch = createSelector(selectFormationsState, (state) => state.search);

export const selectFormationsSearchResultIds = createSelector(selectFormationsSearch, (search) => search.state);

export const selectFormationsSearchCallState = createSelector(selectFormationsSearch, (search) => search.type);

export const selectFormation = createSelector(
  selectAllFormationsEntities,
  (entities: FormationsState['entities'], args: { id: string }) => entities[args.id]
);

export const selectFormationsEntities = createSelector(
  selectAllFormationsEntities,
  (entities: FormationsState['entities'], args: { ids: string[] }) =>
    args.ids.reduce<FormationsState['entities']>(
      (formationEntities, id) => (entities[id] ? { ...formationEntities, [id]: entities[id] } : formationEntities),
      {}
    )
);

export const selectFormations = createSelector(
  selectAllFormationsEntities,
  (entities: FormationsState['entities'], { ids }: { ids: string[] }) => ids.map((id) => entities[id]).filter(Boolean)
);

export const selectOwnedFormations = createSelector(
  selectOwnedFormationsIds,
  selectAllFormationsEntities,
  (ownedFormations, entities) => ownedFormations.map((id) => entities[id]).filter(Boolean)
);

export const selectWishlistFormations = createSelector(
  selectWishlist,
  selectAllFormationsEntities,
  (wishlist, entities) => wishlist.map((id) => entities[id]).filter(Boolean)
);

export const selectCompletedFormations = createSelector(
  selectCompletedFormationsIds,
  selectAllFormationsEntities,
  (completedFormations, entities) => completedFormations.map((id) => entities[id]).filter(Boolean)
);

export const selectOwnedCertificateFormations = createSelector(
  selectOwnedCertificatesIds,
  selectAllFormationsEntities,
  (ownedCertificateFormations, entities) => ownedCertificateFormations.map((id) => entities[id]).filter(Boolean)
);
