<div
  class="container"
  gdColumns="0.25fr auto auto 0.25fr"
  gdRows="repeat(3, 1fr)"
  gdAreas=". . . . | . content image . | . . . ."
  gdGap="32px 32px"
  gdColumns.lt-md="minmax(0, 1fr)"
  gdRows.lt-md="1fr"
  gdAreas.lt-md="content"
>
  <div class="content" gdArea="content" fxLayout="column" fxLayoutGap="32px">
    <div class="header" fxLayout="column" fxLayoutGap="16px">
      <div class="error">
        <span class="mat-caption" *ngIf="status">{{ status }}</span>
        <br />
        <span class="message mat-caption" *ngIf="message">{{ message }}</span>
      </div>
      <h1 i18n="@@not-found-1">Oups!!</h1>
      <h2 i18n="@@not-found-2">Cette page n'est pas celle que vous cherchez</h2>
    </div>

    <div class="footer">
      <p i18n="@@not-found-3">Voici quelques liens utiles à la place</p>
      <div class="links" fxLayout="row" fxLayoutGap="32px">
        <a routerLink="/" i18n="@@not-found-4">Accueil</a>
        <a routerLink="/catalogue/categories" i18n="@@not-found-5">Catalogue</a>
        <a *ngIf="!!user" routerLink="/bibliotheque" i18n="@@not-found-6">Ma bibliothèque</a>
      </div>
    </div>
  </div>

  <div class="image" gdArea="image" fxShow.lt-md="false"></div>
</div>
