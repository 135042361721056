import { createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

const initialState = {
  sidenav: 'close' as 'close' | 'open',
};

export type LayoutState = typeof initialState;

export const openSidenav = createAction('[Layout Store] Open Sidenav');
export const closeSidenav = createAction('[Layout Store] Close Sidenav');
export const toggleSidenav = createAction('[Layout Store] Toggle Sidenav');

export const layoutReducer = createReducer<LayoutState>(
  initialState,
  on(openSidenav, (state) => ({ ...state, sidenav: 'open' })),
  on(closeSidenav, (state) => ({ ...state, sidenav: 'close' })),
  on(toggleSidenav, (state) => ({ ...state, sidenav: state.sidenav === 'open' ? 'close' : 'open' }))
);

export const selectLayoutState = createFeatureSelector<LayoutState>('layout');
export const selectSidenav = createSelector(selectLayoutState, (state) => state.sidenav);
