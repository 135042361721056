/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '~/core';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

window.addEventListener('beforeinstallprompt', (event) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).deferedBeforeInstallPrompt = event;
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
