import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormationCategory, User } from '~/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment, PwaService, version } from '~/core';
import {
  AppState,
  AsyncStateType,
  closeSidenav,
  fetchCategories,
  LayoutState,
  selectCategories,
  selectCategoriesCallState,
  selectSidenav,
  selectUser,
  selectUserCallState,
  toggleSidenav,
  UserState,
} from '~/store';

@Component({
  selector: 'otopod-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  showVersion = environment.showVersion;
  version = version;
  user: User;
  userCallState = 'loading' as UserState['callState'];
  categories: FormationCategory[];
  categoriesCallState = 'loading' as AsyncStateType;
  activeRoute: 'home' | 'catalog' | 'credit-plans' | 'how-it-works';
  mobile: boolean;
  facebookPath = environment.facebookPath;
  linkedInPath = environment.linkedInPath;
  contactMailToPath = 'mailto:' + environment.contactMailToPath;
  sidenavOpened = false;
  currentYear = new Date().getFullYear();

  @ViewChild('sidenav')
  sidenav: MatSidenav;

  @ViewChild('sidenavContent')
  sidenavContent: MatSidenavContent;

  private user$: Observable<User>;
  private userCallState$: Observable<UserState['callState']>;
  private categories$: Observable<FormationCategory[]>;
  private categoriesCallState$: Observable<AsyncStateType>;
  private sidenav$: Observable<LayoutState['sidenav']>;
  private routerEvents$: Observable<Event>;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private mediaObserver: MediaObserver,
    public pwa: PwaService
  ) {
    this.user$ = this.store.select(selectUser).pipe(takeUntil(this.destroy$));

    this.userCallState$ = this.store.select(selectUserCallState).pipe(takeUntil(this.destroy$));

    this.categories$ = this.store.select(selectCategories).pipe(takeUntil(this.destroy$));
    this.categoriesCallState$ = this.store.select(selectCategoriesCallState).pipe(takeUntil(this.destroy$));

    this.sidenav$ = this.store.select(selectSidenav).pipe(takeUntil(this.destroy$));

    this.routerEvents$ = this.router.events.pipe(takeUntil(this.destroy$));
  }

  ngOnInit() {
    this.fetchCategories();

    this.user$.subscribe((user) => {
      this.user = user;
    });

    this.userCallState$.subscribe((callState) => {
      this.userCallState = callState;
    });

    this.categories$.subscribe((categories) => {
      this.categories = categories;
    });

    this.categoriesCallState$.subscribe((callState) => {
      this.categoriesCallState = callState;
    });

    this.routerEvents$.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('catalogue')) {
          this.activeRoute = 'catalog';
        } else if (event.url.includes('nos-plans')) {
          this.activeRoute = 'credit-plans';
        } else if (event.url.includes('information')) {
          this.activeRoute = 'how-it-works';
        } else if (event.url === '/') {
          this.activeRoute = 'home';
        } else {
          this.activeRoute = null;
        }
      }
      this.resetScroll();
    });

    this.mediaObserver.asObservable().subscribe(() => {
      if (this.mediaObserver.isActive('lt-md')) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }

  ngAfterViewInit() {
    this.sidenav$.subscribe((sidenav) => {
      this.sidenavOpened = sidenav === 'open';
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  fetchCategories() {
    this.store.dispatch(fetchCategories());
  }

  toggleSidenav() {
    this.store.dispatch(toggleSidenav());
  }

  closeSidenav() {
    this.store.dispatch(closeSidenav());
  }

  closeSidenavOnMobile() {
    if (this.mobile) {
      this.closeSidenav();
    }
  }

  resetScroll() {
    this.sidenavContent.scrollTo({ top: 0, left: 0 });
  }
}
