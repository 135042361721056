import { createEntityAdapter, EntityState, IdSelector } from '@ngrx/entity';
import { AsyncState, ErrorState, InitState, LoadedState, LoadingState } from './async-state';

export type AsyncEntity<EntityType extends { id: string | number }> = AsyncState<EntityType, { id: EntityType['id'] }>;

export type AsyncEntityState<EntityType extends { id: string | number }> = EntityState<AsyncEntity<EntityType>>;

export const createAsyncEntityAdapter = <EntityType extends { id: string | number }>() =>
  createEntityAdapter<AsyncEntity<EntityType>>({
    selectId: ((entity) => entity.state.id) as IdSelector<AsyncEntity<EntityType>>,
  });

export class InitEntityState<IDType extends string | number> extends InitState<{ id: IDType }> {
  constructor(id: IDType) {
    super({ id });
  }
}

export class LoadingEntityState<IDType extends string | number> extends LoadingState<{ id: IDType }> {
  constructor(id: IDType) {
    super({ id });
  }
}

export class LoadedEntityState<EntityType> extends LoadedState<EntityType> {}

export class ErrorEntityState<IDType extends string | number> extends ErrorState<{ id: IDType }> {
  constructor(id: IDType, message: string, status: number) {
    super({ id }, message, status);
  }
}
