import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DEFAULT_BREAKPOINTS, ImageFormat, NgxPictureModule } from 'ngx-picture';
import { CoreModule, environment } from '~/core';
import { LayoutModule } from '~/features/layout';
import {
  appStore,
  CategoriesEffects,
  FormationsEffects,
  OwnedFormationsEffects,
  OwnedCertificatesEffects,
  UserEffects,
  WishlistEffects,
} from '~/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';

export function srcInterpolator(url: string, imageFormat: ImageFormat, breakpoint: string, breakpointValue: number) {
  const urlPrefix = url.split('.').slice(0, -1).join('.');
  return `${urlPrefix}-${breakpointValue}.${imageFormat === 'jpeg' ? 'jpg' : 'webp'}`;
}

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    EffectsModule.forRoot([
      CategoriesEffects,
      FormationsEffects,
      OwnedFormationsEffects,
      OwnedCertificatesEffects,
      UserEffects,
      WishlistEffects,
    ]),
    LayoutModule,
    MatSnackBarModule,
    NgxPictureModule.forRoot({
      breakpoints: DEFAULT_BREAKPOINTS,
      imageFormats: ['webp', 'jpeg'],
      srcInterpolator,
    }),
    StoreModule.forRoot(appStore, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
