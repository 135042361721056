import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService, FormationAudioStreamService, FormationAudioStreamState, MonitoringService } from '~/core';
import { AppState } from './app.store';
import { AsyncStateType } from './async-state';
import { loadUser, loadUserError, loadUserSuccess } from './user.store';

const initialState = {
  ownedCertificates: [] as string[],
  callState: 'loading' as AsyncStateType,
};

export type OwnedCertificatesState = typeof initialState;

export const addToOwnedCertificates = createAction(
  '[Owned Certificates Store] Add Certificate To Owned Certificates',
  props<{ formationId: string }>()
);
export const addToOwnedCertificatesSuccess = createAction(
  '[Owned Certificates Store] Add Certificate To Owned Certificates Success',
  props<{ ownedCertificates: string[] }>()
);
export const addToOwnedCertificatesError = createAction(
  '[Owned Certificates Store] Add Certificate To Owned Certificates Error'
);

export const clearOwnedCertificates = createAction('[Owned Certificates Store] Clear Owned Certificates');

export const ownedCertificatesReducer = createReducer<typeof initialState>(
  initialState,
  on(loadUser, () => initialState),
  on(loadUserSuccess, (state, { user }) => ({
    ...state,
    ownedCertificates: user.ownedCertificates,
    callState: 'loaded',
  })),
  on(loadUserError, (state) => ({ ...state, callState: 'error' })),
  on(addToOwnedCertificates, (state) => ({
    ...state,
    callState: 'loading',
  })),
  on(addToOwnedCertificatesSuccess, (state, { ownedCertificates }) => ({
    ...state,
    ownedCertificates,
    callState: 'loaded',
  })),
  on(addToOwnedCertificatesError, (state) => ({ ...state, callState: 'loaded' })),
  on(clearOwnedCertificates, () => initialState)
);

@Injectable()
export class OwnedCertificatesEffects {
  addToOwnedCertificates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addToOwnedCertificates),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectOwnedCertificatesIds)))),
      switchMap(([{ formationId }, ownedCertificates]) => {
        if (this.formationAudioStreamState?.formationId === formationId) {
          this.formationAudioStream.dispose();
        }

        return this.api.purchaseCertificate(formationId).pipe(
          map(() => addToOwnedCertificatesSuccess({ ownedCertificates: [...ownedCertificates, formationId] })),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(addToOwnedCertificatesError());
          })
        );
      })
    )
  );

  addToOwnedCertificatesSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addToOwnedCertificatesSuccess),
        tap(() => {
          this.snackBar.open('Le certificat a été ajoutée à votre liste de certificats avec succès.', null, {
            duration: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  addToOwnedCertificatesError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addToOwnedCertificatesError),
        tap(() => {
          this.snackBar.open(
            "Une erreur est survenue lors de l'ajout du certificat a votre liste de certificats.",
            null,
            {
              duration: 5000,
            }
          );
        })
      ),
    { dispatch: false }
  );

  private formationAudioStreamState: FormationAudioStreamState;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
    private monitoring: MonitoringService,
    private snackBar: MatSnackBar,
    private formationAudioStream: FormationAudioStreamService
  ) {
    this.formationAudioStream.getState().subscribe((state) => {
      this.formationAudioStreamState = state;
    });
  }
}

export const selectOwnedCertificatesState = createFeatureSelector<OwnedCertificatesState>('ownedCertificates');
export const selectOwnedCertificatesIds = createSelector(
  selectOwnedCertificatesState,
  (state) => state.ownedCertificates
);
export const selectOwnedCertificatesCallState = createSelector(
  selectOwnedCertificatesState,
  (state) => state.callState
);
