import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'otopod-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit, OnChanges {
  fontIcon: string;

  @Input()
  icon: string;

  @Input()
  size = 32;

  ngOnInit() {
    if (this.icon) {
      this.fontIcon = 'otopod-icons-' + this.icon;
    }
  }

  ngOnChanges() {
    if (this.icon) {
      this.fontIcon = 'otopod-icons-' + this.icon;
    }
  }
}
