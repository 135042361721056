<mat-nav-list fxLayout="column" fxFlexFill>
  <ng-container *ngIf="!!user">
    <div mat-subheader i18n="@@sidenav-1">Mon compte</div>

    <a mat-list-item routerLink="/bibliotheque" routerLinkActive="active-route">
      <mat-icon class="nav-icon">list</mat-icon>
      <span i18n="@@sidenav-2">Bibliothèque</span>
    </a>

    <a
      mat-list-item
      routerLink="/compte/liste-de-souhaits"
      routerLinkActive="active-route"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <otopod-icon class="nav-icon" size="24" icon="heart_dark"></otopod-icon>
      <span i18n="@@sidenav-3">Liste de souhaits</span>
    </a>

    <a mat-list-item routerLink="/compte" routerLinkActive="active-route" [routerLinkActiveOptions]="{ exact: true }">
      <otopod-icon class="nav-icon" size="24" icon="profile_dark"></otopod-icon>
      <span i18n="@@sidenav-4">Profil</span>
    </a>

    <a
      mat-list-item
      routerLink="/compte/portefeuille"
      routerLinkActive="active-route"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon class="nav-icon">account_balance_wallet</mat-icon>
      <span i18n="@@sidenav-5">Portefeuille </span>
    </a>

    <a
      mat-list-item
      routerLink="/compte/certificats"
      routerLinkActive="active-route"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <mat-icon class="nav-icon">school</mat-icon>
      <span i18n="@@sidenav-6">Certificats de formation</span>
    </a>
  </ng-container>

  <mat-divider class="divider" fxShow="false" [fxShow.lt-md]="!!user"></mat-divider>

  <div mat-subheader fxShow="false" fxShow.lt-md="true" i18n="@@sidenav-7">Parcourir</div>

  <a
    mat-list-item
    fxShow="false"
    fxShow.lt-md="true"
    routerLink="/"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="{ exact: true }"
    i18n="@@sidenav-8"
    >Accueil</a
  >

  <a
    mat-list-item
    tabindex="-1"
    fxShow="false"
    fxShow.lt-md="true"
    [class.active-route]="activeRoute === 'catalog'"
    (click)="$event.stopPropagation(); catalogCategoriesExpanded = !catalogCategoriesExpanded"
  >
    <span i18n="@@sidenav-9">Catalogue</span>
    <mat-icon class="menu-button" [class.rotated]="catalogCategoriesExpanded">expand_more</mat-icon>
  </a>

  <div class="submenu" [class.expanded]="catalogCategoriesExpanded">
    <div *ngIf="categoriesCallState === 'loading'" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <mat-spinner diameter="48"></mat-spinner>
    </div>
    <div *ngIf="categoriesCallState === 'error'" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
      <button mat-icon-button color="primary" (click)="$event.stopPropagation(); fetchCategories.next()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="categoriesCallState === 'loaded'">
      <a
        mat-list-item
        *ngFor="let category of categories"
        routerLink="/catalogue/categories/{{ category.id }}"
        routerLinkActive="active-route"
      >
        {{ category.label | localizedString }}
      </a>
    </ng-container>
  </div>

  <a
    fxShow="false"
    fxShow.lt-md="true"
    mat-list-item
    routerLink="/nos-plans"
    routerLinkActive="active-route"
    i18n="@@sidenav-10"
  >
    Nos plans
  </a>

  <a
    fxShow="false"
    fxShow.lt-md="true"
    mat-list-item
    [class.active-route]="activeRoute === 'how-it-works'"
    (click)="$event.stopPropagation(); infoExpanded = !infoExpanded"
    i18n="@@sidenav-11"
  >
    Comment ça marche
    <mat-icon class="menu-button" [class.rotated]="infoExpanded">expand_more</mat-icon>
  </a>

  <div class="submenu" [class.expanded]="infoExpanded">
    <a mat-list-item routerLink="/information/ingenieur" routerLinkActive="active-route" i18n="@@sidenav-12"
      >Recevoir une formation</a
    >
    <a mat-list-item routerLink="/information/expert" routerLinkActive="active-route" i18n="@@sidenav-13"
      >Vendre une formation
    </a>
    <a mat-list-item routerLink="/information/faq" routerLinkActive="active-route" i18n="@@sidenav-14"
      >Questions fréquentes</a
    >
  </div>

  <a
    fxShow="false"
    fxShow.lt-md="true"
    mat-list-item
    routerLink="/a-propos"
    routerLinkActive="active-route"
    i18n="@@sidenav-15"
  >
    À propos
  </a>

  <a
    fxShow="false"
    fxShow.lt-md="true"
    mat-list-item
    routerLink="/nous-contacter"
    routerLinkActive="active-route"
    i18n="@@sidenav-16"
  >
    Nous contacter
  </a>

  <a *ngIf="locale === 'fr'" fxShow="false" fxShow.lt-md="true" mat-list-item (click)="goto('en')"> English </a>

  <a *ngIf="locale === 'en'" fxShow="false" fxShow.lt-md="true" mat-list-item (click)="goto('fr')"> Français </a>

  <div fxFlex="grow"></div>

  <a mat-list-item *ngIf="!!user" (click)="logout()">
    <mat-icon class="nav-icon">logout</mat-icon>
    <span i18n="@@sidenav-17">Se déconnecter</span>
  </a>
</mat-nav-list>
