<button mat-icon-button (click)="onClick()">
  <mat-icon svgIcon="android" inline></mat-icon>
</button>

<ng-template #instructions>
  <div class="container" fxLayout="column">
    <h2 mat-dialog-title i18n="@@android-button-1">Instructions d'installation pour Android</h2>
    <mat-dialog-content fxFlex.lt-sm="grow">
      <mat-list>
        <mat-list-item>
          <h3 i18n="@@android-button-2">
            1. Selectionner "Ajoutez Otopod à l'écran d'accueil", ou bien appuyez sur le boutton paramètres et
            selectionnez "Ajouter à l'écran d'accueil"
          </h3>
          <img class="instruction-image" src="./assets/android_pwa_instructions_1_{{ this.locale }}.jpg" />
        </mat-list-item>
        <mat-list-item>
          <h3 i18n="@@android-button-3">2. Selectionnez "Installer"</h3>
          <img class="instruction-image" src="./assets/android_pwa_instructions_2_{{ this.locale }}.jpg" />
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close i18n="@@android-button-4">Fermer</button>
    </mat-dialog-actions>
  </div>
</ng-template>
