import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { User } from '~/models';
import { environment } from '../environments/environment';

@Injectable()
export class MonitoringService {
  private appInsights: ApplicationInsights;

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
      },
    });

    this.appInsights.loadAppInsights();
  }

  setOrClearAuthenticatedUserContext(user?: User) {
    if (user) {
      this.appInsights.setAuthenticatedUserContext(user.id);
    } else {
      this.appInsights.clearAuthenticatedUserContext();
    }
  }

  logPageView(name?: string, url?: string) {
    console.log('pageView', name, url);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logEvent(name: string, properties?: { [key: string]: any }) {
    console.log(name, properties);
    this.appInsights.trackEvent({ name }, properties);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    console.log('metric', name, average, properties);
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    console.error(exception);
    this.appInsights.trackException({ exception, severityLevel });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logTrace(message: string, properties?: { [key: string]: any }) {
    console.warn(message, properties);
    this.appInsights.trackTrace({ message }, properties);
  }
}
