import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Formation } from '~/models';

@Directive({
  selector: '[otopodFormationListPageEmpty]',
})
export class FormationListEmptyPageDirective {}

@Component({
  selector: 'otopod-formation-list-page',
  templateUrl: './formation-list-page.component.html',
  styleUrls: ['./formation-list-page.component.scss'],
})
export class FormationListPageComponent implements AfterViewInit {
  @Input()
  title: string;

  @Input()
  formations: Formation[];

  @Input()
  callState: 'loaded' | 'loading' | 'error';

  @Input()
  empty: boolean;

  @Input()
  errorMessage: string;

  @Output()
  formationsLoaded = new EventEmitter();

  @Output()
  refetch = new EventEmitter();

  @ContentChild(FormationListEmptyPageDirective, { read: TemplateRef })
  emptyTemplate: TemplateRef<HTMLElement>;

  @ViewChildren('formation')
  formationQueryList: QueryList<ElementRef<HTMLElement>>;

  ngAfterViewInit(): void {
    this.formationQueryList.changes.subscribe((next: QueryList<ElementRef<HTMLElement>>) => {
      if (this.formations.length === next.length) {
        this.formationsLoaded.next();
      }
    });
  }
}
