import { createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { AsyncStateType } from './async-state';
import { loadUser, loadUserError, loadUserSuccess } from './user.store';

const initialState = {
  completedFormations: [] as string[],
  callState: 'loading' as AsyncStateType,
};

export type CompletedFormationsState = typeof initialState;

export const addFormationToCompletedFormations = createAction(
  '[Completed Formations Store] Add Formation To Completed Formations',
  props<{ formationId: string }>()
);

export const clearCompletedFormations = createAction('[Completed Formations Store] Clear Completed Formations');

export const completedFormationsReducer = createReducer<typeof initialState>(
  initialState,
  on(loadUser, () => initialState),
  on(loadUserSuccess, (state, { user }) => ({
    ...state,
    completedFormations: user.completedFormations,
    callState: 'loaded',
  })),
  on(loadUserError, (state) => ({ ...state, callState: 'error' })),
  on(addFormationToCompletedFormations, (state, { formationId }) => ({
    ...state,
    completedFormations: [...state.completedFormations, formationId],
  })),
  on(clearCompletedFormations, () => initialState)
);

export const selectCompletedFormationsState = createFeatureSelector<CompletedFormationsState>('completedFormations');
export const selectCompletedFormationsIds = createSelector(
  selectCompletedFormationsState,
  (state) => state.completedFormations
);
export const selectCompletedFormationsCallState = createSelector(
  selectCompletedFormationsState,
  (state) => state.callState
);
