import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { User } from '~/models';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppState, selectUser } from '~/store';

@Component({
  selector: 'otopod-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  user: User;
  status: number;
  message: string;

  private user$: Observable<User>;
  private error$: Observable<{ message: string; status: number }>;
  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {
    this.user$ = this.store.select(selectUser).pipe(takeUntil(this.destroy$));
    this.error$ = this.route.queryParamMap.pipe(
      map((params) =>
        params.get('status') || params.get('message')
          ? { status: parseInt(params.get('status'), 10), message: params.get('message') }
          : null
      ),
      filter((error) => !!error),
      takeUntil(this.destroy$)
    );
  }

  ngOnInit() {
    this.user$.subscribe((user) => {
      this.user = user;
    });

    this.error$.subscribe((error) => {
      this.status = error.status;
      this.message = error.message;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
