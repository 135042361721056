import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { Store } from '@ngrx/store';
import { User } from '~/models';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import subHours from 'date-fns/subHours';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FormationAudioStreamService, FormationAudioStreamState } from '~/core';
import { addFormationToWishlist, AppState, removeFormationFromWishlist, selectUser, selectWishlist } from '~/store';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'otopod-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit, OnDestroy {
  showPlayer: boolean;
  isPreviewStream: boolean;
  isFirstTrack: boolean;
  isLastTrack: boolean;
  playing: boolean;
  playerPosition: number;
  readableCurrentTime: string;
  duration: number;
  readableDuration: string;
  formationId: string;
  title: string;
  formationTitle: string;
  formationThumbnail: string;
  instructor: string;
  user: User;
  wishlist: string[];
  liked: boolean;
  loading: boolean;
  error: boolean;
  labels = {
    removeFromLikes: $localize`:@@player-code-1:Retirer cette formation de votre liste de souhaits`,
    addToLikes: $localize`:@@player-code-2:Ajouter cette formation à votre liste de souhaits`,
  };

  private state$: Observable<[FormationAudioStreamState, User, string[]]>;
  private playerPosition$ = new Subject<number>();
  private destroy$ = new Subject();
  draggingPlayerPosition: boolean;

  constructor(
    private store: Store<AppState>,
    private auth: AuthService,
    private formationAudioStream: FormationAudioStreamService
  ) {
    const formationAudioStreamState$ = this.formationAudioStream.getState().pipe(takeUntil(this.destroy$));
    const user$ = this.store.select(selectUser).pipe(takeUntil(this.destroy$));
    const wishlist$ = this.store.select(selectWishlist).pipe(takeUntil(this.destroy$));

    this.state$ = new Observable<[FormationAudioStreamState, User, string[]]>((observer) => {
      this.loading = true;
      return combineLatest([formationAudioStreamState$, user$, wishlist$]).subscribe(observer);
    }).pipe(
      tap(([formationAudioStreamState]) => {
        this.loading = false;
        this.error = formationAudioStreamState?.error;
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnInit() {
    this.state$.subscribe(([formationAudioStreamState, user, wishlist]) => {
      if (!formationAudioStreamState) {
        this.showPlayer = false;
        return;
      }

      if (!this.showPlayer) {
        this.showPlayer = formationAudioStreamState.playing;
      }
      this.isPreviewStream = formationAudioStreamState.isPreviewStream;
      this.isFirstTrack = formationAudioStreamState.isFirstTrack;
      this.isLastTrack = formationAudioStreamState.isLastTrack;
      this.playing = formationAudioStreamState.playing;
      this.formationId = formationAudioStreamState.formationId;
      this.formationTitle = formationAudioStreamState.formationTitle;
      this.formationThumbnail = formationAudioStreamState.formationThumbnail;
      this.instructor = formationAudioStreamState.instructor;
      this.title = this.isPreviewStream
        ? 'Extrait'
        : `Chapitre ${formationAudioStreamState.trackInfo.currentTrack + 1}`;
      this.duration = formationAudioStreamState.trackInfo.duration
        ? Math.round(formationAudioStreamState.trackInfo.duration)
        : 0;
      if (!this.draggingPlayerPosition) {
        this.playerPosition$.next(formationAudioStreamState.trackInfo.currentTime);
      }
      this.user = user;
      this.wishlist = wishlist;
      this.liked = wishlist?.includes(this.formationId);
    });

    this.playerPosition$
      .asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((playerPosition) => {
        this.playerPosition = Math.round(playerPosition);
        this.readableCurrentTime = this.formatReadableTime(this.playerPosition);
        this.readableDuration = this.formatReadableTime(this.duration - this.playerPosition);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  togglePlay() {
    if (this.playing) {
      this.pause();
    } else {
      this.play();
    }
  }

  onProgressSliderInput(event: MatSliderChange) {
    this.draggingPlayerPosition = true;
    this.playerPosition$.next(event.value);
  }

  onProgressSliderChange(event: MatSliderChange) {
    this.draggingPlayerPosition = false;
    this.formationAudioStream.seekTo(event.value);
  }

  onLikeClick() {
    if (!this.user) {
      this.auth.loginWithRedirect();
      return;
    }

    if (!this.liked) {
      this.likeFormation();
    } else {
      this.dislikeFormation();
    }
  }

  onCloseClick() {
    this.formationAudioStream.dispose();
  }

  play() {
    this.formationAudioStream.play();
  }

  pause() {
    this.formationAudioStream.pause();
  }

  stop() {
    this.formationAudioStream.stop();
  }

  next() {
    this.formationAudioStream.next();
  }

  previous() {
    this.formationAudioStream.previous();
  }

  private likeFormation() {
    this.store.dispatch(
      addFormationToWishlist({ formationId: this.formationId, originalWishlist: [...this.wishlist] })
    );
  }

  private dislikeFormation() {
    this.store.dispatch(
      removeFormationFromWishlist({ formationId: this.formationId, originalWishlist: [...this.wishlist] })
    );
  }

  private formatReadableTime(playerPosition: number): string {
    const dateWithoutHours = subHours(new Date(0), new Date(0).getHours());
    return format(addSeconds(dateWithoutHours, playerPosition), 'HH:mm:ss');
  }
}
