<div class="container" fxLayout="column">
  <h1 class="title">{{ title }}</h1>

  <div *ngIf="callState === 'loading'" fxFlex="grow" fxLayoutAlign="center">
    <mat-spinner fxFlexAlign="center"></mat-spinner>
  </div>

  <div *ngIf="empty" fxFlex="grow" fxLayout="column" fxLayoutAlign="center center">
    <div>
      <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
    </div>
  </div>

  <div
    class="error-container"
    *ngIf="callState === 'error'"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="grow"
  >
    <h2 fxLayout="center" fxLayoutAlign="center center">
      {{ errorMessage }}
      <button mat-icon-button color="primary" (click)="refetch.next()">
        <mat-icon>refresh</mat-icon>
      </button>
    </h2>
  </div>

  <div *ngIf="callState === 'loaded'" fxLayout="column" fxLayoutGap="32px">
    <ng-container *ngTemplateOutlet="loadedTemplate"></ng-container>
  </div>
</div>
