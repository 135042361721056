import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({
  selector: '[otopodPageLoaded]',
})
export class PageLoadedDirective {}

@Directive({
  selector: '[otopodPageEmpty]',
})
export class PageEmptyDirective {}

@Component({
  selector: 'otopod-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input()
  title: string;

  @Input()
  callState: 'loaded' | 'loading' | 'error' | 'empty';

  @Input()
  empty: boolean;

  @Input()
  errorMessage: string;

  @Output()
  refetch = new EventEmitter();

  @ContentChild(PageEmptyDirective, { read: TemplateRef })
  emptyTemplate: TemplateRef<HTMLElement>;

  @ContentChild(PageLoadedDirective, { read: TemplateRef })
  loadedTemplate: TemplateRef<HTMLElement>;
}
