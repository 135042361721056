import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

@Injectable()
export class RouterHistoryService {
  private history$ = new BehaviorSubject<[string, string]>([this.location.path(), this.location.path()]);

  constructor(private router: Router, private location: Location) {}

  init() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([previous, current]: [NavigationEnd, NavigationEnd]) => {
        this.history$.next([previous?.urlAfterRedirects, current.urlAfterRedirects]);
      });
  }

  getHistory() {
    return this.history$.asObservable();
  }
}
