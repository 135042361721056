import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { FormationCategory, User } from '~/models';
import { LayoutComponent } from '../layout/layout.component';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'otopod-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  catalogCategoriesExpanded = false;
  infoExpanded = false;

  @Input()
  user: User;

  @Input()
  activeRoute: LayoutComponent['activeRoute'];

  @Input()
  categoriesCallState: LayoutComponent['categoriesCallState'];

  @Input()
  categories: FormationCategory[];

  @Output()
  fetchCategories = new EventEmitter();

  constructor(private auth: AuthService, @Inject(LOCALE_ID) public locale: string) {}

  logout() {
    this.auth.logout();
  }

  goto(lang: 'fr' | 'en') {
    window.location.href = window.location.href.replace(/\/(fr|en)\//, `/${lang}/`);
  }
}
