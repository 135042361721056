import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { LocalizedString } from '~/models';

@Pipe({
  name: 'localizedString',
})
export class LocalizedStringPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(value?: LocalizedString): string {
    switch (this.locale) {
      case 'fr':
        return value?.fr || 'Error - Missing french label';
      case 'en':
        return value?.en || 'Error - Missing english label';
      default:
        return 'Error - Unknown locale';
    }
  }
}
