import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutes',
})
export class MinutesPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    const numHours = value / 60;
    const hours = Math.floor(numHours);
    const numMinutes = Math.round((numHours - hours) * 60);

    let minutes = numMinutes ? numMinutes.toString() : '';
    if (numMinutes && numMinutes < 10) {
      minutes = '0' + numMinutes;
    }

    if (!hours) {
      return `${minutes || ''}m`;
    }
    return `${hours}h${minutes || ''}`;
  }
}
