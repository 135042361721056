import { ErrorHandler as NgErrorHandler, Injectable } from '@angular/core';
import { MonitoringService } from './monitoring.service';

@Injectable()
export class ErrorHandler implements NgErrorHandler {
  constructor(private monitoring: MonitoringService) {}

  handleError(error: any): void {
    this.monitoring.logException(error);
  }
}
