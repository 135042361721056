<button mat-icon-button (click)="onClick()">
  <mat-icon svgIcon="apple" inline></mat-icon>
</button>

<ng-template #instructions>
  <div class="container" fxLayout="column">
    <h2 mat-dialog-title i18n="@@apple-button-1">Instructions d'installation pour iOS</h2>
    <mat-dialog-content fxFlex.lt-sm="grow">
      <mat-list>
        <mat-list-item>
          <h3 i18n="@@apple-button-2">1. Appuyez sur le boutton "Partager"</h3>
          <img class="instruction-image" src="./assets/apple_pwa_instructions_1.jpg" />
        </mat-list-item>
        <mat-list-item>
          <h3 i18n="@@apple-button-3">2. Selectionnez "Ajouter sur l'écran d'accueil"</h3>
          <img class="instruction-image" src="./assets/apple_pwa_instructions_2_{{ this.locale }}.jpg" />
        </mat-list-item>
        <mat-list-item>
          <h3 i18n="@@apple-button-4">3. Selectionnez "Ajouter"</h3>
          <img class="instruction-image" src="./assets/apple_pwa_instructions_3_{{ this.locale }}.jpg" />
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial i18n="@@apple-button-5">Fermer</button>
    </mat-dialog-actions>
  </div>
</ng-template>
