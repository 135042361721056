import { Injectable } from '@angular/core';
import { UserFormationProgress } from '~/models';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, pluck, switchMap, throttleTime } from 'rxjs/operators';
import { ApiService } from './api.service';
import { FormationAudioStreamService } from './formation-audio-stream.service';
import { MonitoringService } from './monitoring.service';

export type FormationProgressState = Record<string, UserFormationProgress[]>;

@Injectable()
export class FormationProgressService {
  snapshot: FormationProgressState = {};

  private formationProgress$ = new BehaviorSubject<FormationProgressState>({});

  constructor(
    private api: ApiService,
    private monitoring: MonitoringService,
    private formationAudioStream: FormationAudioStreamService
  ) {
    this.formationAudioStream
      .getState()
      .pipe(throttleTime(3000))
      .subscribe((formationAudioStreamState) => {
        if (!formationAudioStreamState || formationAudioStreamState.isPreviewStream) {
          return;
        }

        const formationId = formationAudioStreamState.formationId;
        const chapterIndex = formationAudioStreamState.trackInfo.currentTrack;
        const timestampSeconds = Math.round(formationAudioStreamState.trackInfo.currentTime);

        this.api.saveFormationProgress(formationId, chapterIndex, timestampSeconds).subscribe(() => {
          const state = {
            ...this.snapshot,
            [formationId]: this.updateFormationProgress(formationId, chapterIndex, timestampSeconds),
          };
          this.snapshot = state;
          this.formationProgress$.next(state);
        });
      });
  }

  getFormationProgress(formationId: string) {
    return this.formationProgress$.asObservable().pipe(
      pluck(formationId),
      switchMap((progress) =>
        progress
          ? of(progress)
          : this.api.getFormationProgressById(formationId).pipe(
              switchMap((formationProgress) => {
                const patchedProgress = [];
                for (const chapterProgress of formationProgress) {
                  patchedProgress[chapterProgress.chapter] = chapterProgress;
                }
                const state = { ...this.snapshot, [formationId]: patchedProgress };
                this.snapshot = state;
                this.formationProgress$.next(state);
                return of(progress);
              }),
              catchError((error) => {
                this.monitoring.logException(error);
                return of(progress);
              })
            )
      )
    );
  }

  private updateFormationProgress = (
    formationId: string,
    chapterIndex: number,
    timestampSeconds: number
  ): UserFormationProgress[] => {
    const formationProgress = this.snapshot[formationId];
    const chapterProgress: UserFormationProgress = {
      chapter: chapterIndex,
      timestampSeconds,
    };

    if (!formationProgress) {
      return [chapterProgress];
    }

    if (!formationProgress.find((progress) => progress?.chapter === chapterIndex)) {
      return Object.assign([], formationProgress, { [chapterIndex]: chapterProgress });
    }

    return formationProgress.map((progress) => (progress.chapter === chapterIndex ? chapterProgress : progress));
  };
}
