import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { FormationCategory, User } from '~/models';
import { environment } from '~/core';
import { LayoutComponent } from '..';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'otopod-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {
  @Input()
  user: User;

  @Input()
  userCallState: LayoutComponent['userCallState'];

  @Input()
  categories: FormationCategory[];

  @Input()
  categoriesCallState: LayoutComponent['categoriesCallState'];

  @Input()
  activeRoute: LayoutComponent['activeRoute'];

  @Output()
  fetchCategories = new EventEmitter();

  @Output()
  toggleSidenav = new EventEmitter();

  @Output()
  closeSidenav = new EventEmitter();

  constructor(public auth: AuthService, @Inject(LOCALE_ID) public locale: string) {}

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/bibliotheque?justLoggedIn=true',
      },
    });
  }

  goto(lang: 'fr' | 'en') {
    window.location.href = window.location.href.replace(/\/(fr|en)\//, `/${lang}/`);
  }

  get filteredCategories(): FormationCategory[] {
    return this.categories.filter((category) => category.id !== environment.freeCategoryId);
  }
}
