<otopod-page
  [title]="title"
  [callState]="callState"
  [empty]="empty"
  [errorMessage]="errorMessage"
  (refetch)="refetch.next()"
>
  <ng-template otopodPageEmpty>
    <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
  </ng-template>

  <ng-template otopodPageLoaded>
    <div
      fxFlex="grow"
      gdColumns="repeat(2, minmax(0, 1fr))"
      gdRows="1fr!"
      gdGap="32px"
      gdColumns.gt-xs="repeat(3, minmax(0, 1fr))"
      gdColumns.gt-sm="repeat(4, minmax(0, 1fr))"
      gdColumns.gt-md="repeat(5, minmax(0, 1fr))"
    >
      <div #formation *ngFor="let formation of formations" class="formation-container">
        <otopod-formation-card [formation]="formation"></otopod-formation-card>
      </div>
    </div>
  </ng-template>
</otopod-page>
