import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SafeUrl } from '@angular/platform-browser';
import { PwaService } from '~/core/services';

@Component({
  selector: 'otopod-mobile-banner',
  templateUrl: './mobile-banner.component.html',
  styleUrls: ['./mobile-banner.component.scss'],
})
export class MobileBannerComponent {
  appStorePath: SafeUrl;
  playStorePath: SafeUrl;

  @ViewChild('androidDialog')
  androidDialogTemplate: TemplateRef<HTMLElement>;

  @ViewChild('iosDialog')
  iosDialogTemplate: TemplateRef<HTMLElement>;

  constructor(public pwa: PwaService, private androidDialog: MatDialog, private iosDialog: MatDialog) {}

  public installOrShowInstructions() {
    if (this.pwa.canInstall()) {
      this.pwa.install();
    } else if (this.pwa.isAndroid) {
      this.showAndroidInstructions();
    } else if (this.pwa.isIOS) {
      this.showIOSInstructions();
    }
  }

  public showAndroidInstructions() {
    this.androidDialog.open(this.androidDialogTemplate, { panelClass: 'mobile-dialog' });
  }

  public showIOSInstructions() {
    this.iosDialog.open(this.iosDialogTemplate, { panelClass: 'mobile-dialog' });
  }
}
