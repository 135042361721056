export type AsyncState<StateType, PartialStateType, ExtraStateType = unknown> =
  | InitState<PartialStateType, ExtraStateType>
  | LoadingState<PartialStateType, ExtraStateType>
  | LoadedState<StateType, ExtraStateType>
  | ErrorState<PartialStateType, ExtraStateType>;

export type AsyncStateType = 'init' | 'loading' | 'loaded' | 'error';

export class InitState<PartialStateType, ExtraStateType = unknown> {
  readonly state: PartialStateType;
  readonly type: 'init';
  readonly extras?: ExtraStateType;

  constructor(state: PartialStateType, extras?: ExtraStateType) {
    return { state, type: 'init', extras };
  }
}

export class LoadingState<PartialStateType, ExtraStateType = unknown> {
  readonly state: PartialStateType;
  readonly type: 'loading';
  readonly extras?: ExtraStateType;

  constructor(state: PartialStateType, extras?: ExtraStateType) {
    return { state, type: 'loading', extras };
  }
}

export class LoadedState<StateType, ExtraStateType = unknown> {
  readonly state: StateType;
  readonly type: 'loaded';
  readonly extras?: ExtraStateType;

  constructor(state: StateType, extras?: ExtraStateType) {
    return { state, type: 'loaded', extras };
  }
}

export class ErrorState<PartialStateType, ExtraStateType = unknown> {
  readonly state: PartialStateType;
  readonly type: 'error';
  readonly error: {
    readonly message: string;
    readonly status: number;
  };
  readonly extras?: ExtraStateType;

  constructor(state: PartialStateType, message: string, status: number, extras?: ExtraStateType) {
    return { state, type: 'error', error: { message, status }, extras };
  }
}

export const createAsyncStateAdapter = <StateType, PartialStateType, ExtraStateType = unknown>() => ({
  getInitialState: (initialState?: AsyncState<StateType, PartialStateType, ExtraStateType>) =>
    initialState || new InitState(null),

  setLoading: (
    currentState: AsyncState<StateType, PartialStateType, ExtraStateType>,
    { partial, extras }: { partial?: PartialStateType; extras?: ExtraStateType } = {}
  ) => new LoadingState(partial || null, extras ? { ...currentState.extras, ...extras } : currentState.extras),

  setLoaded: (
    currentState: AsyncState<StateType, PartialStateType, ExtraStateType>,
    { value, extras }: { value: StateType; extras?: ExtraStateType }
  ) => new LoadedState(value, extras ? { ...currentState.extras, ...extras } : currentState.extras),

  setError: (
    currentState: AsyncState<StateType, PartialStateType, ExtraStateType>,
    {
      message,
      status,
      partial,
      extras,
    }: {
      message: string;
      status: number;
      partial?: PartialStateType;
      extras?: ExtraStateType;
    }
  ) =>
    new ErrorState(
      partial || null,
      message,
      status,
      extras ? { ...currentState.extras, ...extras } : currentState.extras
    ),
});
