import { CommonModule } from '@angular/common';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler as NgErrorHandler,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  LOCALE_ID,
  Provider,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { RouterModule } from '@angular/router';
import {
  ApiService,
  ErrorHandler,
  FormationAudioStreamService,
  FormationProgressService,
  MonitoringService,
  PwaService,
  RouterHistoryService,
} from './services';
import { environment } from './environments/environment';
import {
  Auth0ClientFactory,
  Auth0ClientService,
  AuthClientConfig,
  AuthConfigService,
  AuthGuard,
  AuthHttpInterceptor,
  authHttpInterceptorFn,
  AuthService,
  provideAuth0,
} from '@auth0/auth0-angular';

export function initializeApp(routerHistory: RouterHistoryService) {
  return () => {
    routerHistory.init();
  };
}

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, FlexLayoutModule, MatSnackBarModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [RouterHistoryService] },
        CoreModule.provideAuth(),
        ApiService,
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        { provide: NgErrorHandler, useClass: ErrorHandler },
        FormationAudioStreamService,
        FormationProgressService,
        MonitoringService,
        RouterHistoryService,
        PwaService,
      ],
    };
  }

  static provideAuth(): Provider[] {
    // Copied and adjusted what provideAuth0() does from https://github.com/auth0/auth0-angular/blob/6ac4ab1/projects/auth0-angular/src/lib/provide.ts#L23
    return [
      AuthService,
      AuthHttpInterceptor,
      AuthGuard,
      {
        provide: AuthConfigService,
        useFactory: (localeId: string) => {
          return {
            domain: environment.auth0Domain,
            clientId: environment.auth0ClientId,
            cacheLocation: 'localstorage',
            authorizationParams: {
              redirect_uri: window.location.origin,
              audience: environment.auth0Audience,
              scope: 'read:current_user',
              ui_locales: `${localeId}-CA`,
            },
            httpInterceptor: {
              allowedList: [
                {
                  allowAnonymous: true,
                  uri: `${environment.apiUri}/*`,
                  tokenOptions: {
                    authorizationParams: {
                      audience: environment.auth0Audience,
                      scope: 'read:current_user',
                    },
                  },
                },
              ],
            },
          };
        },
        deps: [LOCALE_ID],
      },
      {
        provide: Auth0ClientService,
        useFactory: Auth0ClientFactory.createClient,
        deps: [AuthClientConfig],
      },
    ];
  }
}
