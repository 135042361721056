import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ApiService, FormationAudioStreamService, FormationAudioStreamState, MonitoringService } from '~/core';
import { AppState } from './app.store';
import { AsyncStateType } from './async-state';
import { loadUser, loadUserError, loadUserSuccess } from './user.store';
import { removeFormationFromWishlistSuccess, selectWishlist } from './wishlist.store';

const initialState = {
  ownedFormations: [] as string[],
  callState: 'loading' as AsyncStateType,
};

export type OwnedFormationsState = typeof initialState;

export const addFormationToOwnedFormations = createAction(
  '[Owned Formations Store] Add Formation To Owned Formations',
  props<{ formationId: string }>()
);
export const addFormationToOwnedFormationsSuccess = createAction(
  '[Owned Formations Store] Add Formation To Owned Formations Success',
  props<{ ownedFormations: string[] }>()
);
export const addFormationToOwnedFormationsError = createAction(
  '[Owned Formations Store] Add Formation To Owned Formations Error'
);

export const clearOwnedFormations = createAction('[Owned Formations Store] Clear Owned Formations');

export const ownedFormationsReducer = createReducer<typeof initialState>(
  initialState,
  on(loadUser, () => initialState),
  on(loadUserSuccess, (state, { user }) => ({ ...state, ownedFormations: user.ownedFormations, callState: 'loaded' })),
  on(loadUserError, (state) => ({ ...state, callState: 'error' })),
  on(addFormationToOwnedFormations, (state) => ({
    ...state,
    callState: 'loading',
  })),
  on(addFormationToOwnedFormationsSuccess, (state, { ownedFormations }) => ({
    ...state,
    ownedFormations,
    callState: 'loaded',
  })),
  on(addFormationToOwnedFormationsError, (state) => ({ ...state, callState: 'loaded' })),
  on(clearOwnedFormations, () => initialState)
);

@Injectable()
export class OwnedFormationsEffects {
  addFormationToOwnedFormations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFormationToOwnedFormations),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectOwnedFormationsIds)))),
      switchMap(([{ formationId }, ownedFormations]) => {
        if (this.formationAudioStreamState?.formationId === formationId) {
          this.formationAudioStream.dispose();
        }

        return this.api.purchaseFormation(formationId).pipe(
          map(() => addFormationToOwnedFormationsSuccess({ ownedFormations: [...ownedFormations, formationId] })),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(addFormationToOwnedFormationsError());
          })
        );
      })
    )
  );

  addFromationToOwnedFormationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFormationToOwnedFormationsSuccess),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectWishlist)))),
      map(([{ ownedFormations }, wishlist]) => {
        this.snackBar.open('La formation a été ajoutée à votre bibliothèque avec succès.', null, {
          duration: 5000,
        });
        return removeFormationFromWishlistSuccess({
          wishlist: wishlist.filter((id) => !ownedFormations.includes(id)),
          notify: false,
        });
      })
    )
  );

  addFromationToOwnedFormationsError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addFormationToOwnedFormationsError),
        tap(() => {
          this.snackBar.open("Une erreur est survenue lors de l'ajout de la formation a votre bibliothèque.", null, {
            duration: 5000,
          });
        })
      ),
    { dispatch: false }
  );

  private formationAudioStreamState: FormationAudioStreamState;

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
    private monitoring: MonitoringService,
    private snackBar: MatSnackBar,
    private formationAudioStream: FormationAudioStreamService
  ) {
    this.formationAudioStream.getState().subscribe((state) => {
      this.formationAudioStreamState = state;
    });
  }
}

export const selectOwnedFormationsState = createFeatureSelector<OwnedFormationsState>('ownedFormations');
export const selectOwnedFormationsIds = createSelector(selectOwnedFormationsState, (state) => state.ownedFormations);
export const selectOwnedFormationsCallState = createSelector(selectOwnedFormationsState, (state) => state.callState);
