<mat-icon
  class="icon"
  fontSet="otopod-icons"
  [fontIcon]="fontIcon"
  [style]="{
    'font-size': size + 'px',
    'line-height': size + 'px',
    width: size + 'px',
    height: size + 'px'
  }"
></mat-icon>
