import { Component, Input } from '@angular/core';
import { Formation } from '~/models';

@Component({
  selector: 'otopod-formation-card',
  templateUrl: './formation-card.component.html',
  styleUrls: ['./formation-card.component.scss'],
})
export class FormationCardComponent {
  @Input()
  formation: Formation;
}
