import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState, selectCompletedFormationsIds } from '~/store';

@Pipe({
  name: 'isQuizCompleted',
})
export class IsQuizCompletedPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(formationId: string): Observable<boolean> {
    return this.store
      .select(selectCompletedFormationsIds)
      .pipe(map((completedFormations) => completedFormations.includes(formationId)));
  }
}
