import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createAction, createFeatureSelector, createReducer, createSelector, on, props, Store } from '@ngrx/store';
import { FormationCategory } from '~/models';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ApiService, MonitoringService } from '~/core';
import { AppState } from './app.store';
import { AsyncState, createAsyncStateAdapter } from './async-state';

export type CategoriesState = AsyncState<FormationCategory[], null>;

const categoriesAdapter = createAsyncStateAdapter<FormationCategory[], null>();

const initialState = categoriesAdapter.getInitialState();

export const fetchCategories = createAction('[Categories Store] Fetch Categories');

export const loadCategories = createAction('[Categories Store] Load Categories Loading');
export const loadCategoriesSuccess = createAction(
  '[Categories Store] Load Categories Success',
  props<{ categories: FormationCategory[] }>()
);
export const loadCategoriesError = createAction(
  '[Categories Store] Load Categories Error',
  props<{ error: { message: string; status: number } }>()
);

export const categoriesReducer = createReducer<CategoriesState>(
  initialState,
  on(loadCategories, (state) => categoriesAdapter.setLoading(state)),
  on(loadCategoriesSuccess, (state, { categories }) => categoriesAdapter.setLoaded(state, { value: categories })),
  on(loadCategoriesError, (state, { error }) =>
    categoriesAdapter.setError(state, { message: error.message, status: error.status })
  )
);

@Injectable()
export class CategoriesEffects {
  fetchCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCategories),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(selectCategories), this.store.select(selectCategoriesCallState))
        )
      ),
      switchMap(([_action, storeCategories, callState]) => {
        if (callState === 'loaded') {
          return of(loadCategoriesSuccess({ categories: storeCategories }));
        }
        return of(loadCategories());
      })
    )
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCategories),
      switchMap(() =>
        this.api.getFormationCategories().pipe(
          switchMap((categories) => of(loadCategoriesSuccess({ categories }))),
          catchError((error) => {
            this.monitoring.logException(error);
            return of(loadCategoriesError({ error: { message: error.message, status: error.status } }));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private api: ApiService,
    private monitoring: MonitoringService
  ) {}
}

export const selectCategoriesState = createFeatureSelector<CategoriesState>('categories');
export const selectCategories = createSelector(selectCategoriesState, (asyncState) => asyncState.state);
export const selectCategoriesCallState = createSelector(selectCategoriesState, (asyncState) => asyncState.type);
