import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPremiumFormation',
})
export class IsPremiumFormationPipe implements PipeTransform {
  transform(price: number): boolean {
    return price > 0;
  }
}
