import { Component, Inject, LOCALE_ID, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PwaService } from '~/core';

@Component({
  selector: 'otopod-android-button',
  templateUrl: './android-button.component.html',
  styleUrls: ['./android-button.component.scss'],
})
export class AndroidButtonComponent {
  @ViewChild('instructions')
  instructionsTemplate: TemplateRef<HTMLElement>;

  constructor(public pwa: PwaService, private dialog: MatDialog, @Inject(LOCALE_ID) public locale: string) {}

  public onClick() {
    if (this.pwa.isAndroid && this.pwa.canInstall()) {
      this.pwa.install();
    } else {
      this.dialog.open(this.instructionsTemplate, { panelClass: 'android-instructions' });
    }
  }
}
