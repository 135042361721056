import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  CreditOrder,
  Formation,
  FormationAttachment,
  FormationCategory,
  FormationChapter,
  FormationComment,
  FormationQuizQuestion,
  FormationTag,
  Instructor,
  PurchaseType,
  QuizSubmission,
  ResponseWrapper,
  Transaction,
  User,
  UserFormationProgress,
} from '~/models';
import { MonoTypeOperatorFunction, Observable, throwError, timer } from 'rxjs';
import { map, mergeMap, retryWhen } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string) {}

  searchFormations(criteria: {
    categoryIds?: string[];
    tagIds?: string[];
    minPublishedDate?: Date;
    maxPublishedDate?: Date;
    minDuration?: number;
    maxDuration?: number;
    search?: string;
    language?: string;
  }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = {} as any;
    if (criteria.categoryIds && criteria.categoryIds.length > 0) {
      params.categoryIds = criteria.categoryIds.join(',');
    }
    if (criteria.tagIds && criteria.tagIds.length > 0) {
      params.tagIds = criteria.tagIds.join(',');
    }
    if (criteria.minPublishedDate) {
      params.minPublishedDate = criteria.minPublishedDate.toISOString();
    }
    if (criteria.maxPublishedDate) {
      params.maxPublishedDate = criteria.maxPublishedDate.toISOString();
    }
    if (criteria.minDuration) {
      params.minDuration = criteria.minDuration.toString();
    }
    if (criteria.maxDuration) {
      params.maxDuration = criteria.maxDuration.toString();
    }
    if (criteria.search) {
      params.search = criteria.search;
    }
    if (criteria.language) {
      params.language = criteria.language;
    }
    return this.get<ResponseWrapper<Formation[]>>(`${environment.apiUri}/api/formations`, {
      params,
    }).pipe(map((r) => r.data));
  }

  getUserFormations() {
    return this.get<ResponseWrapper<Formation[]>>(`${environment.apiUri}/api/formations?purchasedBy=me`).pipe(
      map((r) => r.data)
    );
  }

  getFormation(id: string) {
    return this.get<ResponseWrapper<Formation>>(`${environment.apiUri}/api/formations/${id}`).pipe(map((r) => r.data));
  }

  getFormationChapters(id: string) {
    return this.get<ResponseWrapper<FormationChapter[]>>(`${environment.apiUri}/api/formations/${id}/chapters`).pipe(
      map((r) => r.data)
    );
  }

  getFormationQuizQuestions(id: string) {
    return this.get<ResponseWrapper<FormationQuizQuestion[]>>(`${environment.apiUri}/api/formations/${id}/quiz`).pipe(
      map((r) => r.data)
    );
  }

  getFormationCategories() {
    return this.get<ResponseWrapper<FormationCategory[]>>(`${environment.apiUri}/api/formation-categories`).pipe(
      map((r) => r.data)
    );
  }

  getFormationTags() {
    return this.get<ResponseWrapper<FormationTag[]>>(`${environment.apiUri}/api/formation-tags`).pipe(
      map((r) => r.data)
    );
  }

  submitQuizResult(formationId: string, answers: number[]) {
    return this.post<ResponseWrapper<QuizSubmission>>(
      `${environment.apiUri}/api/formations/${formationId}/quiz-answers`,
      {
        answers,
      }
    ).pipe(map((r) => r.data));
  }

  purchaseFormation(formationId: string) {
    return this.post(
      `${environment.apiUri}/api/purchases?userId=me&formationId=${formationId}&type=${PurchaseType.FORMATION}`,
      null
    );
  }

  purchaseCertificate(formationId: string) {
    return this.post(
      `${environment.apiUri}/api/purchases?userId=me&formationId=${formationId}&type=${PurchaseType.CERTIFICATE}`,
      null
    );
  }

  getMyUser() {
    return this.get<ResponseWrapper<User>>(`${environment.apiUri}/api/users/me`).pipe(map((r) => r.data));
  }

  saveUserProfile(user: Partial<User>) {
    return this.put<ResponseWrapper<User>>(`${environment.apiUri}/api/users/me/profile`, user).pipe(map((r) => r.data));
  }

  createCreditsOrder(quantity: number) {
    return this.post<ResponseWrapper<CreditOrder>>(`${environment.apiUri}/api/credits/orders`, {
      quantity,
      successUrl: `${environment.appUrl}/${this.locale}/nos-plans?result=success&qty=${quantity}`,
      cancelUrl: `${environment.appUrl}/${this.locale}/nos-plans?result=cancel&qty=${quantity}`,
    }).pipe(map((r) => r.data));
  }

  getFormationProgressById(id: string) {
    return this.get<ResponseWrapper<UserFormationProgress[]>>(
      `${environment.apiUri}/api/users/me/formations/${id}/progress`
    ).pipe(map((r) => r.data));
  }

  saveFormationProgress(formationId: string, chapter: number, timestampSeconds: number) {
    return this.put(`${environment.apiUri}/api/users/me/formations/${formationId}/progress/chapters/${chapter}`, {
      timestampSeconds,
    });
  }

  getFormationComments(id: string) {
    return this.get<ResponseWrapper<FormationComment[]>>(`${environment.apiUri}/api/formations/${id}/comments`).pipe(
      map((r) => r.data)
    );
  }

  saveFormationComment(id: string, message: string) {
    return this.post<ResponseWrapper<FormationComment>>(`${environment.apiUri}/api/formations/${id}/comments`, {
      message,
    }).pipe(map((r) => r.data));
  }

  deleteFormationComment(formationId: string, commentId: string) {
    return this.delete(`${environment.apiUri}/api/formations/${formationId}/comments/${commentId}`);
  }

  addFormationToWishList(id: string) {
    return this.post<ResponseWrapper<string[]>>(`${environment.apiUri}/api/users/me/wishlist`, {
      formationId: id,
    }).pipe(map((r) => r.data));
  }

  removeFormationFromWishList(id: string) {
    return this.delete<ResponseWrapper<string[]>>(`${environment.apiUri}/api/users/me/wishlist/${id}`).pipe(
      map((r) => r.data)
    );
  }

  getUserTransactions() {
    return this.get<ResponseWrapper<Transaction[]>>(`${environment.apiUri}/api/users/me/transactions`).pipe(
      map((r) => r.data)
    );
  }

  getFormationAttachments(id: string) {
    return this.get<ResponseWrapper<FormationAttachment[]>>(
      `${environment.apiUri}/api/formations/${id}/attachments`
    ).pipe(map((r) => r.data));
  }

  getInstructor(id: string) {
    return this.get<ResponseWrapper<Instructor>>(`${environment.apiUri}/api/instructors/${id}`).pipe(
      map((r) => r.data)
    );
  }

  getInstructors() {
    return this.get<ResponseWrapper<Instructor[]>>(`${environment.apiUri}/api/instructors`).pipe(map((r) => r.data));
  }

  getCertificate(id: string) {
    return this.http
      .get(`${environment.apiUri}/api/user/me/certificates/${id}`, { responseType: 'text' })
      .pipe(this.retryWithDelayedAttempts());
  }

  submitContactMail(fullName: string, email: string, phoneNumber: string, subject: string, message: string) {
    return this.post(`${environment.apiUri}/api/contact-us/mails`, {
      fullName,
      email,
      phoneNumber,
      subject,
      message,
    });
  }

  private get<T>(...args: Parameters<HttpClient['get']>) {
    return this.http.get<T>(...args).pipe(this.retryWithDelayedAttempts());
  }

  private post<T>(...args: Parameters<HttpClient['post']>) {
    return this.http.post<T>(...args).pipe(this.retryWithDelayedAttempts());
  }

  private put<T>(...args: Parameters<HttpClient['put']>) {
    return this.http.put<T>(...args).pipe(this.retryWithDelayedAttempts());
  }

  private delete<T>(...args: Parameters<HttpClient['delete']>) {
    return this.http.delete<T>(...args).pipe(this.retryWithDelayedAttempts());
  }

  private retryWithDelayedAttempts<T>(maxRetryAttempts = 3, delayMs = 1000): MonoTypeOperatorFunction<T> {
    return retryWhen((errors: Observable<HttpErrorResponse>) =>
      errors.pipe(
        mergeMap((error, index) => {
          if (index >= maxRetryAttempts || error.status > 0 || error.status < 501) {
            return throwError(error);
          }
          return timer(delayMs * index);
        })
      )
    );
  }
}
