<div fxLayout="column" fxFlexFill>
  <otopod-toolbar
    [user]="user"
    [userCallState]="userCallState"
    [categories]="categories"
    [categoriesCallState]="categoriesCallState"
    [activeRoute]="activeRoute"
    (fetchCategories)="fetchCategories()"
    (toggleSidenav)="toggleSidenav()"
    (closeSidenav)="closeSidenav()"
  ></otopod-toolbar>

  <mat-sidenav-container fxFlex="grow" (backdropClick)="toggleSidenav()">
    <mat-sidenav
      #sidenav
      [mode]="mobile ? 'over' : 'side'"
      [opened]="sidenavOpened"
      position="start"
      (click)="closeSidenavOnMobile()"
    >
      <otopod-sidenav
        [user]="user"
        [categories]="categories"
        [categoriesCallState]="categoriesCallState"
        [activeRoute]="activeRoute"
        (fetchCategories)="fetchCategories()"
      ></otopod-sidenav>
    </mat-sidenav>

    <mat-sidenav-content #sidenavContent>
      <ng-content></ng-content>

      <footer
        class="footer"
        gdColumns="repeat(6, 1fr)"
        gdRows="repeat(3, auto)"
        gdAreas="margin-left section1 section2 section3 section4 margin-right | . mobile mobile mobile mobile . | . legal legal legal legal ."
        gdGap="32px 16px"
        gdColumns.lt-sm="repeat(3, 1fr)"
        gdAreas.lt-sm="section1 section2 section3 | mobile mobile mobile | legal legal legal"
        gdGap.lt-sm="16px 8px"
      >
        <div class="margin-left" gdArea="margin-left"></div>
        <mat-nav-list dense class="section1" gdArea="section1" gdGridAlign="center" fxLayout="column">
          <a mat-list-item routerLink="/" i18n="@@layout-1">Accueil</a>
          <a mat-list-item routerLink="/a-propos" i18n="@@layout-2">À propos</a>
          <a mat-list-item [href]="contactMailToPath" i18n="@@layout-3">Nous contacter</a>
          <a mat-list-item [href]="facebookPath"><img src="./assets/facebook.svg" width="32px" /></a>
          <a mat-list-item [href]="linkedInPath"><img src="./assets/linkedin.svg" width="32px" /></a>
        </mat-nav-list>
        <mat-nav-list dense class="section2" gdArea="section2" gdGridAlign="center" fxLayout="column">
          <a mat-list-item routerLink="/nos-plans" i18n="@@layout-4">Nos Plans</a>
          <a mat-list-item routerLink="/information/ingenieur" i18n="@@layout-5">Pour un ingénieur</a>
          <a mat-list-item routerLink="/information/expert" i18n="@@layout-6">Vendre une formation</a>
          <a mat-list-item routerLink="/information/faq" i18n="@@layout-7">Questions fréquentes</a>
        </mat-nav-list>
        <mat-nav-list dense class="section3" gdArea="section3" gdGridAlign="center" fxLayout="column">
          <a mat-list-item [routerLink]="['catalogue', 'categories']" i18n="@@layout-8">Catalogue</a>
          <ng-container *ngFor="let category of categories; let index = index">
            <a
              mat-list-item
              fxShow.lt-sm="false"
              *ngIf="index <= 3"
              [routerLink]="['catalogue', 'categories', category.id]"
            >
              {{ category.label | localizedString }}
            </a>
            <a mat-list-item fxShow="false" fxShow.lt-sm="true" [routerLink]="['catalogue', 'categories', category.id]">
              {{ category.label | localizedString }}
            </a>
          </ng-container>
        </mat-nav-list>
        <mat-nav-list
          dense
          class="section4"
          gdArea="section4"
          gdGridAlign="center"
          fxLayout="column"
          fxShow.lt-sm="false"
        >
          <ng-container *ngFor="let category of categories; let index = index">
            <a mat-list-item *ngIf="index > 3" [routerLink]="['catalogue', 'categories', category.id]">
              {{ category.label | localizedString }}
            </a>
          </ng-container>
        </mat-nav-list>
        <div class="margin-right" gdArea="margin-right"></div>

        <div
          class="mobile"
          *ngIf="!pwa.isInstalled"
          gdArea="mobile"
          fxLayout="row"
          fxLayoutGap="64px"
          gdGridAlign="center center"
        >
          <otopod-apple-button *ngIf="!pwa.isAndroid"></otopod-apple-button>
          <otopod-android-button *ngIf="!pwa.isIOS"></otopod-android-button>
        </div>

        <div class="legal" gdArea="legal" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center">
          <mat-divider></mat-divider>
          <span fxFlexAlign="center" mat-caption i18n="@@copyright"
            >&copy; Copyright {{ currentYear }} - Formations Otopod, Inc.</span
          >
          <span *ngIf="showVersion" fxFlexAlign="center" mat-caption>Version {{ version }}</span>
        </div>

        <img class="yellow-circle" ngClass.lt-md="fade" src="./assets/circle_yellow.svg" />
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <otopod-player class="player"></otopod-player>
</div>
