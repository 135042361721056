import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MonitoringService } from '~/core';
import { AppState, loadUser } from './store';

@Component({
  selector: 'otopod-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  constructor(private store: Store<AppState>, private router: Router, private monitoring: MonitoringService) {}

  ngOnInit() {
    this.store.dispatch(loadUser());

    this.router.events
      .pipe(
        filter((event: unknown): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.monitoring.logPageView(undefined, event.url);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
