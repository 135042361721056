import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxPictureModule } from 'ngx-picture';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  AndroidButtonComponent,
  AppleButtonComponent,
  FormationCardComponent,
  FormationListEmptyPageDirective,
  FormationListPageComponent,
  IconComponent,
  MobileBannerComponent,
  NotFoundComponent,
  PageComponent,
  PageEmptyDirective,
  PageLoadedDirective,
  SkeletonLoaderComponent,
  StarRatingComponent,
  StepperComponent,
  StepperFooterDirective,
  StepperHeaderDirective,
} from './components';
import {
  CreditsPipe,
  DisplayDateToNowPipe,
  IsQuizCompletedPipe,
  IsPremiumFormationPipe,
  MinutesPipe,
  SecondsPipe,
  LocalizedStringPipe,
} from './pipes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NgxPictureModule,
    NgxSkeletonLoaderModule,
    MatDialogModule,
  ],
  declarations: [
    AndroidButtonComponent,
    AppleButtonComponent,
    CreditsPipe,
    DisplayDateToNowPipe,
    FormationCardComponent,
    FormationListPageComponent,
    FormationListEmptyPageDirective,
    IconComponent,
    NotFoundComponent,
    PageComponent,
    PageEmptyDirective,
    PageLoadedDirective,
    IsQuizCompletedPipe,
    IsPremiumFormationPipe,
    MinutesPipe,
    SecondsPipe,
    LocalizedStringPipe,
    SkeletonLoaderComponent,
    StarRatingComponent,
    StepperComponent,
    MobileBannerComponent,
    StepperHeaderDirective,
    StepperFooterDirective,
  ],
  exports: [
    AndroidButtonComponent,
    AppleButtonComponent,
    CreditsPipe,
    DisplayDateToNowPipe,
    FormationCardComponent,
    FormationListPageComponent,
    FormationListEmptyPageDirective,
    IconComponent,
    NotFoundComponent,
    PageComponent,
    PageEmptyDirective,
    PageLoadedDirective,
    IsQuizCompletedPipe,
    IsPremiumFormationPipe,
    MinutesPipe,
    SecondsPipe,
    LocalizedStringPipe,
    SkeletonLoaderComponent,
    StarRatingComponent,
    StepperComponent,
    MobileBannerComponent,
    StepperHeaderDirective,
    StepperFooterDirective,
  ],
})
export class UiModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('android', domSanitizer.bypassSecurityTrustResourceUrl('./assets/android.svg'));
    matIconRegistry.addSvgIcon('apple', domSanitizer.bypassSecurityTrustResourceUrl('./assets/apple.svg'));
  }
}
