<div
  class="formation"
  routerLink="/catalogue/formations/{{ formation.id }}"
  (keyup.enter)="$event.target.click()"
  role="button"
  gdColumns="minmax(0, 1fr)"
  gdRows="min-content min-content"
  gdGap="8px"
>
  <div premium-banner-text="Premium" [ngClass]="{ 'premium-banner': formation.price | isPremiumFormation }">
    <div class="preserve-aspect-ratio">
      <img [src]="formation.thumbnailPath" />
    </div>
  </div>
  <div>
    <h3>{{ formation.title }}</h3>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="center center">
        <img [src]="formation.instructor.thumbnailPath" class="instructor-thumbnail" />
        <span class="mat-caption">{{ formation.instructor.firstName }} {{ formation.instructor.lastName }} </span>
      </div>
      <div fxHide.lt-sm fxLayout="row" fxLayoutAlign="center center" class="duration">
        <ng-container *ngIf="formation.id | isQuizCompleted | async; else notCompleted">
          <mat-icon color="primary">check</mat-icon>
        </ng-container>
        <ng-template #notCompleted>
          <mat-icon>access_time</mat-icon>
        </ng-template>
        <span class="mat-caption">{{ formation.durationMinutes | minutes }}</span>
      </div>
    </div>
  </div>
</div>
