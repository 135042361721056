<div
  class="container color-scheme-dark"
  *ngIf="showPlayer"
  fxLayout="row"
  fxFlexFill
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-md="flex-start flex-start"
>
  <div class="side-content" fxLayout="column">
    <div
      fxLayout="row"
      fxLayoutAlign="flex-start center"
      fxLayoutAlign.lt-md="flex-start flex-start"
      routerLink="/catalogue/formations/{{ formationId }}"
      (keyup.enter)="$event.target.click()"
    >
      <div class="track-details" fxLayout="column" fxFlexAlign="center" fxFlexOrder="2" fxShow.lt-md="false">
        <h5 class="title">{{ title }}</h5>
        <h4 class="formation-title">{{ formationTitle }}</h4>
        <h6 class="instructor">{{ instructor }}</h6>
      </div>
      <img class="formation-thumbnail" [src]="formationThumbnail" fxFlexOrder="1" />
    </div>
  </div>

  <div fxLayout="column" fxLayout.lt-md="row" fxLayoutAlign="center center" fxLayoutAlign.lt-md="flex-start cewnter">
    <div fxLayout="row">
      <button mat-icon-button [disabled]="isFirstTrack" (click)="previous()">
        <otopod-icon icon="prev"></otopod-icon>
      </button>
      <button class="play-button" mat-fab color="primary" (click)="togglePlay()">
        <mat-icon>{{ playing ? 'pause' : 'play_arrow' }}</mat-icon>
      </button>
      <button mat-icon-button [disabled]="isLastTrack" (click)="next()">
        <otopod-icon icon="next"></otopod-icon>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
      <span class="mat-caption otopod-caption" fxShow.lt-md="false">{{ readableCurrentTime }}</span>
      <mat-slider
        class="progress-slider"
        min="0"
        [max]="duration"
        step="1"
        [value]="playerPosition"
        (input)="onProgressSliderInput($event)"
        (change)="onProgressSliderChange($event)"
      ></mat-slider>
      <span class="mat-caption otopod-caption" fxShow.lt-md="false">{{ readableDuration }}</span>
    </div>
  </div>

  <div class="side-content" fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="flex-end center">
      <button mat-icon-button (click)="onCloseClick()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end center" fxShow.lt-md="false">
      <button
        *ngIf="!isPreviewStream"
        mat-icon-button
        i18n-matTooltip="@@player-1"
        matTooltip="Discussions à propos de cette formation"
        [routerLink]="['catalogue', 'formations', formationId, 'commentaires']"
      >
        <otopod-icon icon="chat_light"></otopod-icon>
      </button>
      <button
        *ngIf="isPreviewStream"
        mat-icon-button
        [color]="liked ? 'primary' : undefined"
        [matTooltip]="liked ? labels.removeFromLikes : labels.addToLikes"
        (click)="onLikeClick()"
      >
        <otopod-icon [icon]="liked ? 'heart_dark' : 'heart_light'"></otopod-icon>
      </button>
    </div>
  </div>
</div>
