<div class="mobile" fxLayout="column" fxLayoutAlign="center center" *ngIf="!pwa.isInstalled">
  <ngx-picture src="./assets/telephone.jpg"></ngx-picture>

  <div class="mobile-body" fxFlexFill gdColumns="1.25fr 1fr" gdColumns.lt-sm="1fr">
    <div gdColumn="2 / 3" fxLayout="column" fxLayoutAlign="center center">
      <ng-container *ngIf="pwa.canInstall() && (pwa.isAndroid || pwa.isIOS); else instructions">
        <h2 (click)="pwa.install()">
          <span i18n="@@mobile-banner-1"
            >Pour encore plus de mobilité, installez sur votre cellulaire 100% gratuitement!</span
          >
          <mat-icon>download</mat-icon>
        </h2>
      </ng-container>

      <ng-template #instructions>
        <div fxLayout="column" fxLayoutGap="32px">
          <h2 i18n="@@mobile-banner-2">
            Pour encore plus de mobilité, installez sur votre cellulaire 100% gratuitement!
          </h2>
          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="32px">
            <otopod-apple-button *ngIf="!pwa.isAndroid"></otopod-apple-button>
            <otopod-android-button *ngIf="!pwa.isIOS"></otopod-android-button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
