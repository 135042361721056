import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { NotFoundComponent } from '~/features/ui';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('~/features/home').then((m) => m.HomeModule),
  },
  {
    path: 'catalogue',
    loadChildren: () => import('~/features/catalog').then((m) => m.CatalogModule),
  },
  {
    path: 'bibliotheque',
    loadChildren: () => import('~/features/library').then((m) => m.LibraryModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'nos-plans',
    loadChildren: () => import('~/features/credits').then((m) => m.CreditsModule),
  },
  {
    path: 'information',
    loadChildren: () => import('~/features/information').then((m) => m.InformationModule),
  },
  {
    path: 'compte',
    loadChildren: () => import('~/features/account').then((m) => m.AccountModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'a-propos',
    loadChildren: () => import('~/features/about').then((m) => m.AboutModule),
  },
  {
    path: 'nous-contacter',
    loadChildren: () => import('~/features/contact').then((m) => m.ContactModule),
  },
  {
    path: 'theme-test',
    loadChildren: () => import('~/features/theme-test').then((m) => m.ThemeTestModule),
  },
  {
    path: 'oups',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/oups',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
