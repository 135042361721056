import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FormationChapter } from '~/models';
import { of, Subject } from 'rxjs';
import { AudioStream, RxJSAudioService, StreamConfig, StreamState } from 'rxjs-audio';
import { catchError, filter, take, takeUntil } from 'rxjs/operators';
import { MonitoringService } from './monitoring.service';

export type FormationAudioStreamTrack = { url: string };

export type FormationAudioStreamData = {
  formationId: string;
  formationTitle: string;
  formationThumbnail: string;
  instructor: string;
  chapters: FormationChapter[];
  isPreviewStream: boolean;
};

export type FormationAudioStreamState = StreamState &
  FormationAudioStreamData & {
    error: boolean;
  };

type FormationAudioStreamEvent = {
  type:
    | 'ended'
    | 'error'
    | 'play'
    | 'playing'
    | 'pause'
    | 'timeupdate'
    | 'canplay'
    | 'loadedmetadata'
    | 'loadstart'
    | 'next'
    | 'previous';
};

@Injectable()
export class FormationAudioStreamService {
  private rxjsAudioService = new RxJSAudioService();
  private audioStream: AudioStream;
  private state: FormationAudioStreamState;
  private state$ = new Subject<FormationAudioStreamState>();
  private events$ = new Subject<FormationAudioStreamEvent>();
  private dispose$ = new Subject();
  private createStream$ = new Subject();
  private lastTrackEnded: boolean;

  constructor(private monitoring: MonitoringService, private snackBar: MatSnackBar) {
    this.getState().subscribe((state) => {
      this.state = state;
    });

    this.dispose$.subscribe(() => {
      this.state$.next(null);
    });
  }

  createStream(tracks: FormationAudioStreamTrack[], data: FormationAudioStreamData, config: StreamConfig = {}) {
    this.createStream$.next();

    this.audioStream = this.rxjsAudioService.create(tracks, { urlKey: 'url', autoPlayNext: true, ...config });
    this.audioStream
      .getState()
      .pipe(takeUntil(this.createStream$), takeUntil(this.dispose$))
      .subscribe(
        (state: StreamState) => {
          if (state) {
            const formationAudioStreamState = { ...state, ...data, error: false };
            this.state$.next(formationAudioStreamState);
          }
        },
        (error) => {
          this.monitoring.logException(error);
          this.snackBar.open("Une erreur est survenue lors de l'écoute de la formation.", null, { duration: 5000 });
          this.state$.next({ ...this.state, error: true });
          this.stop();
        },
        () => {
          this.stop();
        }
      );

    this.audioStream
      .events()
      .pipe(
        catchError((error) => {
          this.monitoring.logException(error);
          return of({ type: 'error' });
        }),
        takeUntil(this.createStream$),
        takeUntil(this.dispose$)
      )
      .subscribe((event) => {
        if (event.type === 'ended' && this.state.isLastTrack) {
          this.lastTrackEnded = true;
        } else if (event.type === 'playing' && this.lastTrackEnded) {
          this.dispose();
          this.lastTrackEnded = false;
        }
        this.events$.next(event);
      });
  }

  dispose() {
    this.stop();
    if (!this.state?.playing) {
      this.dispose$.next();
    } else {
      this.events()
        .pipe(
          filter((event) => event.type === 'pause'),
          take(1)
        )
        .subscribe(() => {
          this.dispose$.next();
        });
    }
  }

  events() {
    return this.events$.asObservable();
  }

  getState() {
    return this.state$.asObservable();
  }

  play() {
    this.audioStream.play();
  }

  playAt(index: number) {
    this.audioStream.playAt(index);
  }

  pause() {
    this.audioStream.pause();
  }

  stop() {
    this.audioStream.stop();
  }

  next() {
    this.audioStream.next();
    this.play();
  }

  previous() {
    this.audioStream.previous();
    this.play();
  }

  switchTo(index: number) {
    this.audioStream.switchTo(index);
  }

  seekTo(time: number) {
    this.audioStream.seekTo(time);
  }
}
